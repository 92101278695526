

















import { computed } from '@vue/composition-api';
import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    VueTelInput
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    inputText: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },

  setup(props, ctx) {
    const styleClasses = computed(() => {
      if (props.inputText === 'black') {
        return ['vti__input', 'vue-tel-input:focus-within'];
      }
      return ['vti__input', 'input-text', 'vue-tel-input:focus-within'];
    });
    const inputObject = {
      styleClasses: styleClasses.value,
      placeholder: 'Phone'
    };
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { inputValue, inputObject };
  }
};
